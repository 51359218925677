import onInit from "../utils/onInit";
import * as GroupToggle from "../components/GroupToggle";
import Cmd from "../utils/Cmd";
const scrollableContainerSelector = ".m-group-nav__list--tabs";
const prevArrowSelector = ".js-group-nav-prev-arrow";
const nextArrowSelector = ".js-group-nav-next-arrow";
/**
 * NOTICE: This is used only behind the `new_sticky_tabs_flag` flag
 */
function initNavArrows() {
    const isMobile = Skubacz.Device.screen.isMobileSize();
    if (isMobile) {
        return;
    }
    const containerEl = document.querySelector(scrollableContainerSelector);
    const prevArrowEl = document.querySelector(prevArrowSelector);
    const nextArrowEl = document.querySelector(nextArrowSelector);
    if (!containerEl || !prevArrowEl || !nextArrowEl) {
        return;
    }
    // Function to check scroll position and toggle arrow visibility
    const updateArrowVisibility = () => {
        const showArrowDistance = 5;
        const { scrollLeft, scrollWidth, clientWidth } = containerEl;
        // Show/hide left arrow
        if (scrollLeft > showArrowDistance) {
            prevArrowEl.classList.add("is-visible");
        }
        else {
            prevArrowEl.classList.remove("is-visible");
        }
        // Show/hide right arrow
        if (scrollLeft + clientWidth + showArrowDistance < scrollWidth) {
            nextArrowEl.classList.add("is-visible");
        }
        else {
            nextArrowEl.classList.remove("is-visible");
        }
    };
    // Initial check
    updateArrowVisibility();
    // Add event listeners for scroll and resize
    containerEl.addEventListener("scroll", updateArrowVisibility);
    window.addEventListener("resize", updateArrowVisibility);
    const isFirefox = Skubacz.Device.browser.isFirefox();
    const isEdge = Skubacz.Device.browser.isEdge();
    // Arrows click events
    prevArrowEl.addEventListener("click", () => {
        // FIXME: think about more rogust scroll mechanism with scroll-snap
        // Explanation: scrollBy doesn't work properly with `scroll-snap-align: start; scroll-snap-stop: always;` styles on some browsers, so using scrollTo by 60% of container width as an alternative
        if (isFirefox || isEdge) {
            const left = Math.max(0, containerEl.scrollLeft - containerEl.clientWidth * 0.6);
            containerEl.scrollTo({ left, behavior: "smooth" });
        }
        else {
            containerEl.scrollBy({ left: -200, behavior: "smooth" });
        }
    });
    nextArrowEl.addEventListener("click", () => {
        if (isFirefox || isEdge) {
            const left = containerEl.scrollLeft + containerEl.clientWidth * 0.6;
            containerEl.scrollTo({ left, behavior: "smooth" });
        }
        else {
            containerEl.scrollBy({ left: 200, behavior: "smooth" });
        }
    });
}
function initScrollingToActiveTab() {
    const containerEl = document.querySelector(scrollableContainerSelector);
    if (!containerEl) {
        return;
    }
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            var _a, _b;
            if (mutation.attributeName === "class" &&
                mutation.target instanceof HTMLElement &&
                mutation.target.classList.contains("is-active")) {
                containerEl.scrollTo((_b = (_a = mutation.target.parentElement) === null || _a === void 0 ? void 0 : _a.offsetLeft) !== null && _b !== void 0 ? _b : mutation.target.offsetLeft, 0);
            }
        });
    });
    observer.observe(containerEl, {
        attributes: true,
        attributeFilter: ["class"],
        subtree: true,
    });
}
function getTarget(item) {
    return (GroupToggle.utils.getItemFromHash(item.getAttribute("href") || "") ||
        item.getAttribute("data-target") ||
        "");
}
export default {
    /**
     * Initiate GroupToggleNav state.
     *
     * @param {string} state Current item.
     */
    init: (state = "") => [state, Cmd.none],
    update: (state, action) => {
        switch (action.type) {
            case "CHANGE_ITEM":
                return [action.target, Cmd.none];
        }
        return [state, Cmd.none];
    },
    view: (elements, state, dispatch) => {
        elements.forEach((link) => {
            if (getTarget(link) === state) {
                link.classList.add("is-active");
            }
            else {
                link.classList.remove("is-active");
            }
        });
        onInit(elements[0], () => {
            elements.forEach((el) => {
                el.addEventListener("click", (e) => {
                    const target = getTarget(e.currentTarget);
                    if (GroupToggle.utils.isGroupToggleItem(target)) {
                        dispatch({ type: "CHANGE_ITEM", target });
                        e.preventDefault();
                    }
                });
            });
            initNavArrows();
            initScrollingToActiveTab();
        });
    },
};
